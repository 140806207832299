// import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";
import Navbar from "./Navbar";
import Slider from "./slider";
import About from "./About";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Slider />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
