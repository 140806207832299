import React,{ useEffect,useState, useRef} from "react";
import * as Tone from 'tone';

import img2 from './images/play.png';
import img3 from './images/pause.png';







const Slider = (min, max, step) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [frequency, setFrequency] = useState(0);

  const osc = useRef(null);

  useEffect(() => {
    // create a new oscillator
    osc.current = new Tone.Oscillator(frequency, "sine").toDestination();
    
    

    // start the oscillator
   if(isPlaying){
    Tone.start();
    osc.current.start();
   }
    

    // stop the oscillator when the component unmounts
    return () => {
      osc.current.stop();
     
    };
  }, [frequency, isPlaying]);

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
    console.log(frequency)
  };

  const handlePlayPause = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      osc.current.start();
    } else {
      setIsPlaying(false);
      osc.current.stop();
    }
  };
  


  
  


    

  
    const text1 = `Frequencies below 20Hz cannot beheard by humans, but can be felt`;
    const text2 = `Deep bass frequencies,around 50Hz,can only be produced by subwoofers or headphones`;
    const text3 = `Mid bass frequencies,around 100Hz,such asthe thumb of bass guitar or the kick drum in or a drum set`;
    const text4 = `Upper bass frequencies around 150Hz,
    relate to the midrange sounds in music,
    such as the body of a guitar or the warmth of sexaphone`;
    const displayedText =
    frequency< 50
      ? text1
      : frequency < 100
      ? text2
      : frequency < 150
      ? text3
      : text4;

    const value1 = 10;
    const vallue2 = frequency
    const displayfreq = 
    frequency<10
      ? value1
      : vallue2
      
  
  
  


  
  
  
  
  const click = () =>{
    let displayImage = document.getElementById('btn1')
    if(displayImage.src.match(img2)){
      displayImage.src = img3;
      

      
      

    
    }
    else if(displayImage.src.match(img3)){
      displayImage.src = img2;

      
      
      
    }
    else{
      displayImage.src='./images/pause.png';
      
      
      
    }
   
    
  }
  const calculateColor = () => {
    let percent;
    if (frequency<95) {
     percent = ((frequency/2+2) / 100) * 100;
    }
    else if(frequency>95 && frequency<155){
      percent = ((frequency/2-1) / 100) * 100;

    }
    else if(frequency>185 && frequency<195){
      percent = ((frequency/2-2) / 100) * 100;

    }
    else if(frequency===200){
      percent = ((frequency/2) / 100) * 100;

    }
    else{
      percent = ((frequency/2-1) / 100) * 100;

    }
    
    return `linear-gradient(90deg, #B25068 ${percent}%, #774360 ${percent}%)`;
  };

  const sliderStyle = {
    background: calculateColor(),
  };
 
  
  
  return (
    <div>
      <h2 id="value_of_freq2">{displayfreq} Hz</h2>
      <div className="show_prop" id="properties"  ><p>{displayedText}</p></div>
     <div className="above_scale">

       <p id="para1">Frequencies below 20Hz cannot be heard by humans, but can be felt</p>
       <div className="verticalLine"></div>
       
       <p id="para2">Deep bass frequencies around 50Hz, can only be produced by subwoofers or headphones</p>
       
       <p id="para3">Mid bass frequencies,around 100Hz,such as the thumb of bass guitar or the kick drum in or a drum set</p>
       <pl id='para4'>Upper bass frequencies around 150Hz,
        relate to the midrange sounds in music, 
         such as the body of a guitar or the warmth of sexaphone</pl>

    </div>
    <div className="sliderContainer">
    <input type="range" className="slider"  value={frequency} onChange={handleFrequencyChange} style={sliderStyle} min={0} max={200} step={1} id="customRange3"></input>
       
   
     
      <div className="scale">
        <div className="line" id='ten'>
          
          
         
        </div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>


        <div className="line">

        

        </div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>


        <div className="line">
       

        </div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>


        <div className="line">
        

        </div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>
        <div className="line" id="inline"></div>


        <div className="line" id="twohund"></div>
      </div>
      <div className="sacle_value"><p id="tenp">10Hz</p><p id="50p">50Hz</p><p id="100p">100Hz</p><p id="150p">150Hz</p><p id="thundp">200Hz</p></div>
      <h2 id="value_of_freq"> {displayfreq}Hz</h2>

      <div className="music_btn" >
      <button id="btn"><img id='btn1'src={img2} onClick={() => {
        click();
        handlePlayPause()
        
        
        
      
        
      
      }} alt="" />{isPlaying}</button>
    </div>

      

    </div>
    
    </div>

  );
};

export default Slider;