import React from "react";
import img1 from './images/logo (2).png';
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg ">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={img1} alt="" />
        </Link>
        
          <Link class="nav-link"  to="/about">About</Link>
        
      </div>
    </nav>
  );
};

export default Navbar;
