import React from "react";

const About = () => {
  return (
    <div>
      <div className="about_page">
        <h1 id="about_title"> About us </h1> <div className="hr"> </div>{" "}
        <p>
          {" "}
          We build apps for android devices with beautiful and easy to use User
          Interface{" "}
        </p>{" "}
      </div>{" "}
      <div className="about_page2">
        <div className="contain1">
          <a
            href="https://play.google.com/store/apps/dev?id=5773773301592341983"
            className="card1"
            id="card1"
          >
            <img src="" alt="" />
            <p> Playstore </p>{" "}
          </a>{" "}
          <p> Check out our apps on our Play Store developer page </p>{" "}
        </div>{" "}
        <div className="contain1">
          <a
            href="https://www.instagram.com/japp.io/"
            className="card2"
            id="card2"
          >
            <img src="" alt="" />
            <p> Instagram </p>{" "}
          </a>{" "}
          <p> Follow us on Instagram for getting the latest updates </p>{" "}
        </div>{" "}
        <div className="contain2">
          <a href="https://japp.io/" className="card3" id="card3">
            <p> Our blogs </p>{" "}
          </a>{" "}
          <p> Check out the blogs written by us </p>{" "}
        </div>{" "}
      </div>{" "}
      <div className="about_page3">
        <h1> Our Story </h1>{" "}
        <div className="story">
          <div className="left">
            {" "}
            We started to build apps as a way to help people to be more
            productive and to achieve more.All of our apps are precisely
            designed to meet our user’ s needs with easy - to - use and Modern
            Design UI.{" "}
          </div>{" "}
          <div className="left">
            {" "}
            We currently have more than 1, 500, 000 + downloads and around 500,
            000 + active users on our apps.All of our apps have a very positive
            rating on the Play Store with users recommending our apps to other
            users.{" "}
          </div>
        </div>{" "}
      </div>{" "}
      <div className="footer">
        {" "}
        <p> Copyright© 2023 japp.io </p>
      </div>
    </div>
  );
};

export default About;
